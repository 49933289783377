import React from 'react';
import * as AiIcons from 'react-icons/ai';
import * as BiIcons from 'react-icons/bi';
import * as CgIcons from 'react-icons/cg';
import * as GiIcons from 'react-icons/gi';
import * as IoIcons from 'react-icons/io5';
import * as RiIcons from 'react-icons/ri';

export const SidebarData = [
 /*    {
      id: 1,
      title: 'Dashboard',
      icon: <BiIcons.BiBarChart />,
      iconClosed: <RiIcons.RiArrowDownSFill />,
      iconOpened: <RiIcons.RiArrowUpSFill />,
      actived: false,
    subNav: [
         {
            id: 11,
            activedSub: false,
            title: '$SUPERCAKE',
            path: '/dashboard/superpancake',
         },
         {
            id: 12,
            activedSub: false,
            title: '$SUPERSTAKE',
            path: '/dashboard/superstake',
         },
      ],
   },*/
 /*  {
      id: 2,
      title: 'Staking',
      icon: <GiIcons.GiFarmTractor />,
      iconClosed: <RiIcons.RiArrowDownSFill />,
      iconOpened: <RiIcons.RiArrowUpSFill />,
      actived: false,
      subNav: [
   {
            id: 21,
            activedSub: false,
            title: 'Zelda',
            path: '/superstaking/superstake-superstake',
            cName: 'sub-nav',
                comingsoon: true,
         },
         {
            id: 21,
            activedSub: false,
            title: 'SUPERSTAKE/SUPERSTAKE',
            path: '/superstaking/superstake-superstake',
            cName: 'sub-nav',
                comingsoon: true,
         },
      ],
   },*/
   {
      id: 2,
      title: 'Staking',
      path: '/staking',
      icon: <GiIcons.GiFarmTractor />,
      iconClosed: <RiIcons.RiArrowDownSFill />,
      iconOpened: <RiIcons.RiArrowUpSFill />,
      actived: false
   },
   {
      id: 3,
      title: 'Swap',
      path: '/comingsoon',
      icon: <CgIcons.CgArrowsExchange />,
      comingsoon: true,
   },
   {
      id: 4,
      title: 'Marketplace',
      path: '/comingsoon',
      icon: <IoIcons.IoStorefrontOutline />,
      actived: false,
      comingsoon: true,
   },
   {
      id: 5,
      title: 'NFTCollectibles',
      path: '/comingsoon',
      icon: <AiIcons.AiOutlinePicture />,
      actived: false,
      comingsoon: true,
   },
   {
      id: 6,
      title: 'Chart',
      path: '/comingsoon',
      icon: <AiIcons.AiOutlineLineChart />,
      actived: false,
      comingsoon: true,
   },
   {
      id: 7,
      title: 'Game',
      path: '/comingsoon',
      icon: <IoIcons.IoGameControllerOutline />,
      actived: false,
      comingsoon: true,
   },
];

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IconContext } from 'react-icons';
import * as HiIcons from 'react-icons/hi2';
import * as MdIcons from 'react-icons/md';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import '../../pages/css/variables.scss';
import FlagsModal from '../flags-lng/flags';
import './styles.scss';

const NavIcon = styled(Link)`
   margin-left: 1rem;
   font-size: 2rem;
   height: 80px;
   display: flex;
   justify-content: flex-start;
   align-items: center;
   color: var(--white);

   svg path {
      color: var(--text-color);
   }
`;

const BtnDarkLight = styled.div`
   margin: 0 20px 0 0;
   button {
      background: none;
      font-size: 1.9rem;
      cursor: pointer;
      border: none;
      display: flex;
   }
`;

const Top = ({ props }) => {
   const [sidebar, setSidebar] = useState(checkDevice());
   const [banner, setBanner] = useState(false);
   const [theme, setTheme] = useState();

   useEffect(() => {
      const hasTheme = localStorage.getItem('theme');
      console.log();

      if (hasTheme === 'light') {
         document.body.className = hasTheme;
         setTheme(hasTheme);
      } else {
         setTheme('dark');
         document.body.className = theme;
      }
   }, [theme]);

   useEffect(() => {
      showSidebar();
   }, []);

   useEffect(
      (e) => {
         setTimeout(() => {
            setBanner(!banner);
         }, 12000);
      },
      [banner]
   );

   function checkDevice() {
      if (
         navigator.userAgent.match(/Android/i) ||
         navigator.userAgent.match(/webOS/i) ||
         navigator.userAgent.match(/iPhone/i) ||
         navigator.userAgent.match(/iPad/i) ||
         navigator.userAgent.match(/iPod/i) ||
         navigator.userAgent.match(/BlackBerry/i) ||
         navigator.userAgent.match(/Windows Phone/i)
      ) {
         return true; // is mobile
      } else {
         return false; // is not mobile
      }
   }

   const { t } = useTranslation();

   function showSidebar() {
      if (!sidebar) {
         document.getElementById('sidemenu').style.width = '300px';
         document.getElementById('footer').style.display = 'flex';
         document.getElementById('content-wrapper').style.gridTemplateColumns = '300px auto';

         setSidebar(!sidebar);
      } else {
         document.getElementById('sidemenu').style.width = '0';
         document.getElementById('footer').style.display = 'none';
         document.getElementById('content-wrapper').style.gridTemplateColumns = '0 100%';
         setSidebar(!sidebar);
      }
   }

   const toggleTheme = () => {
      if (theme === 'light') {
         localStorage.setItem('theme', 'dark');
         setTheme('dark');
      } else {
         localStorage.setItem('theme', 'light');
         setTheme('light');
      }
   };
   return (
      <IconContext.Provider value={{ color: '#fff' }}>
         <div className='header'>
            <NavIcon to='#' title={t(sidebar ? 'HideMenu' : 'ViewMenu')} id='view-menu'>
               {!sidebar ? <HiIcons.HiBars3 onClick={showSidebar} /> : <MdIcons.MdMenuOpen onClick={showSidebar} />}
            </NavIcon>
            <div className='ads'>
            
            </div>
            <div className='group-wallet'>
               <BtnDarkLight>
                  <button onClick={toggleTheme} className='set-theme'>
                     {theme === 'dark' ? (
                        <MdIcons.MdLightMode color='#e4a812' title={t('LightMode')} />
                     ) : (
                        <MdIcons.MdNightlight color='#12434d' title={t('DarkMode')} />
                     )}
                  </button>
               </BtnDarkLight>
               <FlagsModal />
               <div id='walletplace' style={{ display: 'grid' }}>
                  {props}
               </div>
            </div>
         </div>
      </IconContext.Provider>
   );
   return (
      <IconContext.Provider value={{ color: '#fff' }}>
         <div className='header'>
            <NavIcon to='#' title={t(sidebar ? 'HideMenu' : 'ViewMenu')} id='view-menu'>
               {!sidebar ? <HiIcons.HiBars3 onClick={showSidebar} /> : <MdIcons.MdMenuOpen onClick={showSidebar} />}
            </NavIcon>
            <div className='ads'>
               {banner && sidebar && <img src={'/ads/ads-1.gif'} alt='ads' />}
               {!banner && sidebar && <img src={'/ads/728-x-90.gif'} alt='ads' />}
            </div>
            <div className='group-wallet'>
               <BtnDarkLight>
                  <button onClick={toggleTheme} className='set-theme'>
                     {theme === 'dark' ? (
                        <MdIcons.MdLightMode color='#e4a812' title={t('LightMode')} />
                     ) : (
                        <MdIcons.MdNightlight color='#12434d' title={t('DarkMode')} />
                     )}
                  </button>
               </BtnDarkLight>
               <FlagsModal />
               <div id='walletplace' style={{ display: 'grid' }}>
                  {props}
               </div>
            </div>
         </div>
      </IconContext.Provider>
   );
};

export default Top;

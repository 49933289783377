import { ethers } from 'ethers';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import average from '../../assets/img/average.svg';
import dollar from '../../assets/img/dollar.svg';
import phone_card from '../../assets/img/phone_card.svg';
import refresh from '../../assets/img/refresh.svg';
import stake from '../../assets/img/stake.svg';
import total_supply from '../../assets/img/total_supply.svg';
import wallet from '../../assets/img/wallet.svg';
import './styles.scss';

const Web3 = require('web3-eth');

let stakes = {
   total_toclaim: '0',
   total_amount: '0',
   stakes: [[0, 0, 0, 0, 0]],
};
let stakeshistoric = [];
let ullist;
let ready = false;

const Stakemodal = (props) => {
   let provider = props.array[0];
   let contract = props.array[1];
   let stakesabi = props.array[2];
   let tokenct = props.array[3];
   let tokenabi = props.array[4];
   let logo = props.array[5];
   let logos = props.array[6];
   let logor = props.array[7];
   let TKS = props.array[8];
   let accounts = [props.array[9]];
   let TKSD = props.array[10];
   let TKRD = props.array[11];
   let cake = props.array[12];
   let lastprice = props.array[13];

   const [active, setActive] = useState(1);
   const [isthistimeline, setTimeline] = useState(365);
   const { t } = useTranslation();
   const [tokenB, settokenB] = useState('0x00000000000000000000000000000000');
   const [tokenR, settokenR] = useState(undefined);
   const [stakect, setStake] = useState([{}]);
   const [STAKECTS, setSTAKECTS] = useState(contract);
   const [AllowA, setAllowA] = useState(0);
   const [__amount, set__amount] = useState(0);
   const [accountNRSA, setAccountNRSA] = useState(0);
   const [accountNRSC, setAccountNRSC] = useState(0);
   const [accountNRSCOLD, setAccountNRSCOLD] = useState(0);
   const [totalstaked, settotalstaked] = useState(0);
   const [accountBalancemTB, setAccountBalanceTB] = useState(0);
   const [APR, setAPR] = useState([
      {
         1: {
            APR: '0',
         },
         3: {
            APR: '0',
         },
         6: {
            APR: '0',
         },
         12: {
            APR: '0',
         },
      },
   ]);
   const [tlock, setTlock] = useState(1);
   const [tapr, setTAPR] = useState(0);
   const [seconds, setSeconds] = useState(0);
   const [totalSupplyB, setTotalSupplyB] = useState(0);
   const [account, setAccount] = useState([{}]);
   const [prices, setprice] = useState(0);
   const [pricetotalsupply, setpricetotalsupply] = useState(0);
   const [pricetotalstaked, setpricetotalstaked] = useState(0);
   const [pricetotalbalance, setpricetotalbalance] = useState(0);
   const [pricetotalstakeduser, setpricetotalstakeduser] = useState(0);
   const [pricetotalbnbprice, setpricetotalbnbprice] = useState(0);

   const [pricecurrentbnb, setpricecurrentbnb] = useState(0);
   const [estimatereward, setestimateprice] = useState(0);
   const [amountpriceusd, setamountpriceusd] = useState(0);
   const [amountprice, setamountprice] = useState(0);
   const [estimaterewardusd, setestimatepriceusd] = useState(0);

   const [userdata, setuserdata] = useState({
      1: {
         amount: 0,
         reward: 0,
      },
      3: {
         amount: 0,
         reward: 0,
      },
      6: {
         amount: 0,
         reward: 0,
      },
      12: {
         amount: 0,
         reward: 0,
      },
   });

   const web3 =
      provider !== 'https://bsc-dataseed.binance.org'
         ? new Web3(provider)
         : new Web3(new Web3.providers.HttpProvider(provider));

   //28800 + 35.853 + 44976 + 59837
   useEffect(() => {
      let interval = null;
      interval = setInterval(() => {
         //if(seconds <= 3)
         setSeconds((seconds) => seconds + 3000);
         defineapricetoken();
         data();
      }, 1000);
      return () => clearInterval(interval);
   }, [seconds]);
   async function calcapr(_stake, lock) {
      const aprDiscount = await _stake.methods.APRDISCOUNT(lock).call();

      const tsamt = nextblock(await _stake.methods._totalstakedIRL(lock).call(), TKSD);
      const rwamt = nextblock(await _stake.methods._totalinreward().call(), TKRD);
      const APR = rwamt !== 0 && tsamt !== 0 ? (rwamt * 100) / tsamt : 0;
      return ((APR * aprDiscount) / 100).toFixed(2);
   }

   async function data() {
      if (STAKECTS !== null) {
         const _stakect = new web3.Contract(stakesabi, STAKECTS);
         setStake(_stakect);
         call(_stakect.methods.BEP20Mtoken, gettokendataA);
         call(_stakect.methods.BEP20Rtoken, settokenR);
         call(_stakect.methods._totalstaked, settotalstaked);
         setAPR([
            {
               1: {
                  APR: await calcapr(_stakect, 1),
               },
               3: {
                  APR: await calcapr(_stakect, 3),
               },
               6: {
                  APR: await calcapr(_stakect, 6),
               },
               12: {
                  APR: await calcapr(_stakect, 12),
               },
            },
         ]);

         if (accounts[0] !== undefined) {
            try {
               const jacare = await _stakect.methods.Moves(accounts[0]).call();
               let list = [];
               for (let index = 0; index < jacare['stakes'].length; index++) {
                  if (jacare['stakes'][index]['user'] === accounts[0]) {
                     list.push(jacare['stakes'][index]);
                  }
               }
               stakeshistoric = list;
               ready = false;
            } catch (error) {
               console.log(error);
            }

            const userdatas = [
               {
                  1: {
                     amount: await _stakect['methods']['amount'](accounts[0], 1).call(),
                     reward: await _stakect['methods']['rewardinstake'](accounts[0], 1).call(),
                  },
                  3: {
                     amount: await _stakect['methods']['amount'](accounts[0], 3).call(),
                     reward: await _stakect['methods']['rewardinstake'](accounts[0], 3).call(),
                  },
                  6: {
                     amount: await _stakect['methods']['amount'](accounts[0], 6).call(),
                     reward: await _stakect['methods']['rewardinstake'](accounts[0], 6).call(),
                  },
                  12: {
                     amount: await _stakect['methods']['amount'](accounts[0], 12).call(),
                     reward: await _stakect['methods']['rewardinstake'](accounts[0], 12).call(),
                  },
               },
            ];
            const staked = await _stakect['methods']['userTotalinAmount'](accounts[0]).call();
            const reward = await _stakect['methods']['userTotalinReward'](accounts[0]).call();

            setAccountNRSA(staked.toString());
            setAccountNRSC(reward.toString());
            setuserdata(userdatas);
         }
      }
   }
   async function gettokendataA(e) {
      const token = new web3.Contract(tokenabi, e);
      settokenB(token);
      call(token.methods.totalSupply, setTotalSupplyB);
      if (accounts[0] !== undefined) {
         call(token.methods.allowance, setAllowA, accounts[0], STAKECTS);
         call(token.methods.balanceOf, setAccountBalanceTB, accounts[0]);
      }
   }

   function update() {
      props.callupdate();
      ready = false;
   }

   function nextpercent(accountBalancemctTB, d) {
      return parseFloat(accountBalancemctTB + '').toFixed(2);
   }
   function er(e) {
      if (e.toString().includes('.')) {
         return '.';
      } else {
         if (e.toString().includes(',')) {
            return ',';
         } else {
            return '';
         }
      }
   }
   function nextblock(accountBalancemctTB, d) {
      if (parseInt(accountBalancemctTB) === 0) {
         return 0;
      }
      let a = (accountBalancemctTB / 10 ** d).toString();
      if (er(a).includes('.') || er(a).includes(',')) {
         if (accountBalancemctTB.toString().length > d) {
            return a.split(er(a))[0] + '.' + a.split(er(a))[1].slice(0, 2);
         } else {
            if (accountBalancemctTB.toString().length === d) {
               return '0.' + accountBalancemctTB.toString().slice(0, 2);
            } else {
               return (
                  '0.' +
                  '0'.repeat(d - accountBalancemctTB.toString().length) +
                  accountBalancemctTB.toString().slice(0, 2)
               );
            }
         }
      } else {
         return a;
      }
   }

   function getmkerror(e) {
      let a = '';
      if (e.includes('{')) {
         let b = e.split('{');
         let c = '{' + b[1];
         let aaa = JSON.parse(c);
         return aaa.message;
      } else {
         a = e;
         return a;
      }
   }

   function nextblocknodecimal(accountBalancemctTB, d) {
      if ((accountBalancemctTB / 10 ** d).toString().includes('.')) {
         if (accountBalancemctTB.toString().length >= d) {
            return (accountBalancemctTB / 10 ** d).toString().split('.')[0];
         } else {
            return (
               '0.' + '0'.repeat(d - accountBalancemctTB.toString().length) + accountBalancemctTB.toString().slice(0, 2)
            );
         }
      } else {
         return accountBalancemctTB / 10 ** d;
      }
   }

   function call(func, callback, ...args) {
      // Trigger the function with the arguments
      if (accounts !== null) {
         if (accounts[0] !== undefined) {
            func(...args)
               .call()
               .then((result) => {
                  // Apply given callback, this is our stateSetters
                  callback(result);
               })
               .catch((error) => { });
         }
      }
   }
   async function sendTX(func, callback, _value, ...args) {
      func(...args)
         .estimateGas({ from: accounts[0], value: _value })
         .then((gas) => {
            func(...args)
               .send({
                  from: accounts[0],
                  value: _value,
                  gas: gas,
                  gasPrice: (3 * (10 ** 9)).toString()
               })

               .then((gg) => {
                  update();
                  props.myalert('Success');
               })
               .catch((e) => {
                  props.myalert(getmkerror(e.message));
               });
         });
   }
   function nstake(value) {


      let e = getvalue(value);

      if (e === 0) {
         props.myalert('Input invalid');
      } else {
         sendTX(stakect.methods._stake, '', '5000000000000000', e, tlock);
      }
   }
   function nwAstake(lock) {
      sendTX(stakect.methods._withdrawStakeA,'', '5000000000000000', lock);
   }
   function nwRstake(lock) {
      sendTX(stakect.methods._withdrawStakeR, '', '5000000000000000', lock);
   }
   function approveta() {
      tokenB.methods
         .approve(STAKECTS, totalSupplyB)
         .send({
            from: accounts[0],
            gasPrice: (3 * (10 ** 9)).toString()
         })
         .then((gg) => {
            update();
            props.myalert('Success');
         })
         .catch((error) => {
            props.myalert(getmkerror(error.message));
         });
   }
   //new stake
   function getvalue(e) {
      let a = [''];
      if (e === 0) {
         return 0;
      }
      if (e.toString().includes(',')) {
         a = e.toString().split(',');
         e = ethers.utils.parseUnits(a[0] + '.' + a[1], TKSD).toString();
      } else {
         e = ethers.utils.parseUnits(e.toString(), TKSD).toString();
      }
      return e;
   }

   function estimaterewards(value) {
      let e = getvalue(value);
      if (e === 0) {
         setestimateprice(0);
         setestimatepriceusd(0);
      } else {
         defineReward(setestimateprice, e);
      }
   }
   function estimateamount(value) {
      let e = getvalue(value);
      if (e === 0) {
         setamountprice(0);
         setamountpriceusd(0);
      } else {
         defineamountprice(setamountprice, e);
      }
   }
  async function checkAllow(value) {
      call(tokenB.methods.allowance, setAllowA, accounts[0], STAKECTS);
      set__amount(value)
   }
   async function define(func, amount, toct) {
      if (amount !== 0)
         if (amount < 1 && tokenR !== undefined) {
         } else {
            if (toct === '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c') {
               const amountsB = await cake.methods
                  .getAmountsOut(amount, [
                     '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
                     '0xe9e7cea3dedca5984780bafc599bd69add087d56',
                  ])
                  .call();
               func(amountsB[1]);
            } else {
               const amountsA =
                  cake !== undefined
                     ? await cake.methods
                        .getAmountsOut(amount, [toct, '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c'])
                        .call()
                     : undefined;
               const amountsB =
                  amountsA !== undefined
                     ? await cake.methods
                        .getAmountsOut(amountsA[1], [
                           '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
                           '0xe9e7cea3dedca5984780bafc599bd69add087d56',
                        ])
                        .call()
                     : [0, 0];
               func(amountsB[1]);
            }
         }
   }
   async function defineReward(func, amount) {
      if (amount < 1) {
      } else {
         if (cake !== undefined && tokenR !== undefined) {
            if (tokenR === '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c') {
               const amountsB = await cake.methods
                  .getAmountsOut(amount, [tokenct, '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c'])
                  .call();
               func(amountsB[1]);
               define(setestimatepriceusd, amountsB[1], tokenR);
            } else {
               const amountsA =
                  cake !== undefined
                     ? await cake.methods
                        .getAmountsOut(amount, [tokenct, '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c'])
                        .call()
                     : undefined;
               const amountsB =
                  amountsA !== undefined
                     ? await cake.methods
                        .getAmountsOut(amountsA[1], ['0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c', tokenR])
                        .call()
                     : [0, 0];
               func(amountsB[1]);
               define(setestimatepriceusd, amountsB[1], tokenR);
            }
         }
      }
   }
   async function defineamountprice(func, amount) {
      if (amount < 1) {
      } else {
         if (cake !== undefined && tokenR !== undefined) {
            if (tokenR === '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c') {
               const amountsB = await cake.methods
                  .getAmountsOut(amount, [tokenct, '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c'])
                  .call();
               func(amountsB[1]);
               define(setamountpriceusd, amountsB[1], tokenR);
            } else {
               const amountsA =
                  cake !== undefined
                     ? await cake.methods
                        .getAmountsOut(amount, [tokenct, '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c'])
                        .call()
                     : undefined;
               const amountsB =
                  amountsA !== undefined
                     ? await cake.methods
                        .getAmountsOut(amountsA[1], ['0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c', tokenR])
                        .call()
                     : [0, 0];
               func(amountsB[1]);
               define(setamountpriceusd, amountsB[1], tokenR);
            }
         }
      }
   }
   function defineapricetoken() {
      if (cake !== undefined && tokenR !== undefined) {
         define(setprice, '1' + '0'.repeat(TKSD), tokenct);
         define(setpricetotalsupply, totalSupplyB, tokenct);
         define(setpricetotalstaked, totalstaked, tokenct);
         define(setpricetotalbalance, accountBalancemTB, tokenct);
         define(setpricetotalstakeduser, accountNRSA, tokenct);
         //bnb
         define(setpricetotalbnbprice, accountNRSC, tokenR);
         define(setpricecurrentbnb, '1' + '0'.repeat(18), tokenR);
      }
   }

   function seechange() {
      let bal;
      if (accountNRSC !== accountNRSCOLD) {
         bal = (
            <p style={{ fontSize: '14px' }}>
               <span style={{ fontSize: '20px' }}>{nextblock(accountNRSC, TKRD)}</span>
               <br /> ${nextblock(pricetotalbnbprice, 18)} USD
            </p>
         );

         setTimeout(function () {
            bal = (
               <p>
                  <span>{nextblock(accountNRSC, TKRD)}</span>
                  <br /> ${nextblock(pricetotalbnbprice, 18)} USD
               </p>
            );
            setAccountNRSCOLD(accountNRSC);
         }, 1000);
      } else {
         bal = (
            <p>
               <span>{nextblock(accountNRSC, TKRD)}</span>
               <br /> ${nextblock(pricetotalbnbprice, 18)} USD
            </p>
         );
      }
      return bal;
   }

   let balancereward = seechange();
   const ButtonBLP = (props) => {
      const mouthtxt = props.lock === 1 ? t('Month') : t('Months');
      return (
         <div className='table_body'>
            <div className='row'>
               <button style={{ backgroundColor: props.color }}>{props.type}</button>
               <p>
                  {props.lock} {mouthtxt}
               </p>
               <p>{props.when}</p>
               <p>
                  <img src={props.logo} alt='' />
                  {props.symbol}
               </p>
               <p id={props.type + props.amount}>{props.price}</p>
            </div>
         </div>
      );
   };

   function Blp(props) {
      const SOC =
         props.type === 'Stake' ? (
            <ButtonBLP
               logo={logos}
               symbol={TKS[0]}
               type={props.type}
               lock={props.lock}
               color={'#38c368'}
               when={props.when}
               amount={props.amount}
               price={getpricefromamount(props.amount, props.type)}
            />
         ) : (
            <ButtonBLP
               logo={logos}
               symbol={TKS[0]}
               type={props.type}
               lock={props.lock}
               color={'#ff0000'}
               when={props.when}
               amount={props.amount}
               price={getpricefromamount(props.amount, props.type)}
            />
         );
      const a =
         props.type === 'Claim' ? (
            <ButtonBLP
               logo={logor}
               symbol={TKS[1]}
               type={props.type}
               lock={props.lock}
               color={'#ffc63c'}
               when={props.when}
               amount={props.amount}
               price={getpricefromclaim(props.amount, props.type)}
            />
         ) : (
            SOC
         );
      return a;
   }

   const listb = stakeshistoric;

   let ulbets = null;
   const ze = () => {
      if (ready) {
         return ullist;
      } else {
         if (listb === null) {
            ulbets = (
               <div className='table_area over'>
                  <div className='table_body'>
                     <div className='row'>
                        <p>{t('Type')}</p>
                        <p>{'lock'}</p>
                        <p>{t('Date')}</p>
                        <p>{t('Asset')}</p>
                        <p>{t('Amount')}</p>
                     </div>
                  </div>
               </div>
            );
            ullist = ulbets;
            return ulbets;
         } else {
            ulbets = (
               <div className='table_area over'>
                  <div className='table_body'>
                     <div className='row'>
                        <p>{t('Type')}</p>
                        <p>{'lock'}</p>
                        <p>{t('Date')}</p>
                        <p>{t('Asset')}</p>
                        <p>{t('Amount')}</p>
                     </div>
                  </div>
                  <div className='listtable'>
                     {listb
                        .map(
                           (e) =>
                              e.since >= parseInt((Date.now() + -isthistimeline * 24 * 3600 * 1000) / 1000) && (
                                 <Blp
                                    type={e.types}
                                    account={e.user}
                                    when={new Date(e.since * 1000).toLocaleString()}
                                    whents={e.since}
                                    amount={e.amount}
                                    lock={e.lock}
                                 />
                              )
                        )
                        .reverse()}
                  </div>
               </div>
            );
            ullist = ulbets;
            ready = true;
            return ulbets;
         }
      }
   };
   function getpricefromclaim(amount, type) {
      if (accounts !== null) {
         if (accounts[0] !== undefined) {
            if (cake !== undefined) {
               _getUSDpricetoINHTML(type + amount, amount, tokenR, TKRD);
            }
         }
      }
   }
   function getpricefromamount(amount, type) {
      if (accounts !== null) {
         if (accounts[0] !== undefined) {
            _getUSDpricetoINHTML(type + amount, amount, tokenct, TKSD);
         }
      }
   }

   function getamounts(lock) {
      if (userdata[0] === undefined) {
         return 0;
      }
      let value = userdata[0][lock]['amount'];
      let id = TKS[0] + 'to' + TKS[1] + lock + 'AMOUNTSUSD';
      if (document.getElementById(id) !== null) {
         getUSDpricetoINHTML(id, value, tokenct, TKSD);
      }
      return value;
   }
   function getrewards(lock) {
      if (userdata[0] === undefined) {
         return 0;
      }
      let value = userdata[0][lock]['reward'];
      let id = TKS[0] + 'to' + TKS[1] + lock + 'REWARDSUSD';
      if (document.getElementById(id) !== null) {
         getUSDpricetoINHTML(id, value, tokenR, TKRD);
      }
      return value;
   }

   async function getUSDpricetoINHTML(id, value, tokenct, dec) {
      if (value > 0) {
         if (tokenct === '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c') {
            const amountsB = await cake.methods
               .getAmountsOut(value, [
                  '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
                  '0xe9e7cea3dedca5984780bafc599bd69add087d56',
               ])
               .call();
            if (document.getElementById(id) !== null) {
               document.getElementById(id).innerHTML = '$' + nextblock(amountsB[1], 18) + ' USD';
            }
         } else {
            const amountsA =
               cake !== undefined
                  ? await cake.methods
                     .getAmountsOut(value, [tokenct, '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c'])
                     .call()
                  : undefined;
            const amountsB =
               amountsA !== undefined
                  ? await cake.methods
                     .getAmountsOut(amountsA[1], [
                        '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
                        '0xe9e7cea3dedca5984780bafc599bd69add087d56',
                     ])
                     .call()
                  : [0, 0];

            if (document.getElementById(id) !== null) {
               document.getElementById(id).innerHTML = '$' + nextblock(amountsB[1], 18) + ' USD';
            }
         }
      }
   }
   async function _getUSDpricetoINHTML(id, value, tokenct, dec) {
      if (tokenct === '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c') {
         const amountsB = await cake.methods
            .getAmountsOut(value, [
               '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
               '0xe9e7cea3dedca5984780bafc599bd69add087d56',
            ])
            .call();
         if (document.getElementById(id) !== null) {
            document.getElementById(id).innerHTML =
               nextblock(value, dec) + '<br/> $' + nextblock(amountsB[1], 18) + ' USD';
         }
      } else {
         const amountsA =
            cake !== undefined
               ? await cake.methods.getAmountsOut(value, [tokenct, '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c']).call()
               : undefined;
         const amountsB =
            amountsA !== undefined
               ? await cake.methods
                  .getAmountsOut(amountsA[1], [
                     '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
                     '0xe9e7cea3dedca5984780bafc599bd69add087d56',
                  ])
                  .call()
               : [0, 0];

         if (document.getElementById(id) !== null) {
            document.getElementById(id).innerHTML =
               nextblock(value, dec) + '<br/> $' + nextblock(amountsB[1], 18) + ' USD';
         }
      }
   }

   let dollarUSLocale = Intl.NumberFormat('en-US');

   function getper(e) {
      return e.toString().includes('.') ? e.toString().split('.')[0] + '.' + e.toString().split('.')[1].slice(0, 2) : e;
   }
   function percentoldprice() {
      return getper((prices * 100) / lastprice - 100);
   }
   function isactive(e) {
      return isthistimeline === e ? 'active' : '';
   }
   const Stakebutton = (props) => {
      if (parseFloat(nextblock(AllowA, TKSD)) > parseFloat(__amount)) {
         return (
            <button
               onClick={() => {
                  if (document.getElementById(TKS[0] + TKS[1]) !== null) {
                     nstake(document.getElementById(TKS[0] + TKS[1]).value);
                  } else {
                     props.myalert('Erro, Please report');
                  }
               }}
            >
               STAKE
            </button>
         );
      } else {
         return (
            <button
               onClick={() => {
                  approveta();
               }}
            >
               APPROVE POOL
            </button>
         );
      }
   };
   const Card = (props) => {
      return (
         <div className='card'>
            <img src={props.logo} alt='' />
            <div className='presentation'>
               <p>
                  {props.symbol} {props.title}
               </p>
               <h1>{props.data}</h1>
               <span>{props.subData}</span>
            </div>
         </div>
      );
   };
   const CardStake = (props) => {
      const mouthtxt = props.mouth === 1 ? t('Month') : t('Months');
      return (
         <>
            <div className='row_wrapper row_wrapper_second'>
               <div className='left_side'>
                  <img src={props.logo} alt='' />
                  <div className='presentation'>
                     <h1>
                        {props.symbol[0]}’s <br />
                        <span>{nextblock(props.amount, TKSD)}</span>{' '}
                        <p id={props.symbol[0] + 'to' + props.symbol[1] + props.mouth + 'AMOUNTSUSD'}>
                           {document.getElementById(
                              props.symbol[0] + 'to' + props.symbol[1] + props.mouth + 'AMOUNTSUSD'
                           ) !== null &&
                              document.getElementById(
                                 props.symbol[0] + 'to' + props.symbol[1] + props.mouth + 'AMOUNTSUSD'
                              ).innerHTML}
                        </p>{' '}
                     </h1>
                  </div>
               </div>
               <button className='row_button'>
                  {props.mouth} {mouthtxt} | {props.hand}
               </button>

               <div className='input_wrapper unstakebutton'>
                  <button
                     onClick={() => {
                        nwAstake(props.mouth);
                     }}
                  >
                     {t('Unstake')}
                  </button>
               </div>
            </div>
            <div style={{ display: 'grid', width: '100%', color: '#888' }}>
               {props.mouth === 1 && (
                  <p
                     aria-label={
                        'Only applies within ' +
                        props.mouth +
                        ' ' +
                        mouthtxt +
                        ' of staking. Unstaking after ' +
                        props.mouth +
                        ' ' +
                        mouthtxt +
                        ' will not include a fee. Timer resets on every stake'
                     }
                     data-balloon-pos='up-right'
                     data-balloon-length='medium'
                     style={{ margin: 'auto', fontSize: '12px', textDecoration: 'underline' }}
                  >
                     15% Withdraw Fee Until
                  </p>
               )}
               {props.mouth === 3 && (
                  <p
                     aria-label={
                        'Only applies within ' +
                        props.mouth +
                        ' ' +
                        mouthtxt +
                        ' of staking. Unstaking after ' +
                        props.mouth +
                        ' ' +
                        mouthtxt +
                        ' will not include a fee. Timer resets on every stake'
                     }
                     data-balloon-pos='up-right'
                     data-balloon-length='medium'
                     style={{ margin: 'auto', fontSize: '12px', textDecoration: 'underline' }}
                  >
                     20% Withdraw Fee Until
                  </p>
               )}
               {props.mouth === 6 && (
                  <p
                     aria-label={
                        'Only applies within ' +
                        props.mouth +
                        ' ' +
                        mouthtxt +
                        ' of staking. Unstaking after ' +
                        props.mouth +
                        ' ' +
                        mouthtxt +
                        ' will not include a fee. Timer resets on every stake'
                     }
                     data-balloon-pos='up-right'
                     data-balloon-length='medium'
                     style={{ margin: 'auto', fontSize: '12px', textDecoration: 'underline' }}
                  >
                     25% Withdraw Fee Until
                  </p>
               )}
               {props.mouth === 12 && (
                  <p
                     aria-label={
                        'Only applies within ' +
                        props.mouth +
                        ' ' +
                        mouthtxt +
                        ' of staking. Unstaking after ' +
                        props.mouth +
                        ' ' +
                        mouthtxt +
                        ' will not include a fee. Timer resets on every stake'
                     }
                     data-balloon-pos='up-right'
                     data-balloon-length='medium'
                     style={{ margin: 'auto', fontSize: '12px', textDecoration: 'underline' }}
                  >
                     30% Withdraw Fee Until
                  </p>
               )}
            </div>
         </>
      );
   };
   const CardReward = (props) => {
      const mouthtxt = props.mouth === 1 ? t('Month') : t('Months');
      const TKS = props.symbol;
      return (
         <div className='row_wrapper row_wrapper_second'>
            <div className='left_side'>
               <img src={props.logo} alt='' />
               <div className='presentation'>
                  <h1>
                     {TKS[1]}’s <br />
                     <span id={TKS[0] + 'to' + TKS[1] + props.mouth + 'REWARDS'}>{nextblock(props.amount, TKRD)}</span>
                     <p id={TKS[0] + 'to' + TKS[1] + props.mouth + 'REWARDSUSD'}>
                        {document.getElementById(TKS[0] + 'to' + TKS[1] + props.mouth + 'REWARDSUSD') !== null &&
                           document.getElementById(TKS[0] + 'to' + TKS[1] + props.mouth + 'REWARDSUSD').innerHTML}
                     </p>{' '}
                  </h1>
               </div>
            </div>
            <button className='row_button'>
               {props.mouth} {mouthtxt} | {props.hand}
            </button>
            <div className='input_wrapper claimbutton'>
               <button
                  onClick={() => {
                     nwRstake(props.mouth);
                  }}
               >
                  {t('Claim')}
               </button>
            </div>
         </div>
      );
   };
   return (
      <div className='inner_body'>
         <div className='cards_wrapper' style={{ overflow: "auto" }}>
            <Card
               logo={dollar}
               symbol={'$' + TKS[0]}
               title={t('Price')}
               data={'$' + nextblock(prices, 18)}
               subData={percentoldprice() + '%'}
            />
            <Card
               logo={total_supply}
               symbol={'$' + TKS[0]}
               title={t('Supply')}
               data={dollarUSLocale.format(nextblocknodecimal(totalSupplyB, TKSD))}
               subData={'$' + nextblock(pricetotalsupply, TKSD)}
            />
            <Card
               logo={stake}
               symbol={'$' + TKS[0]}
               title={t('Staked')}
               data={dollarUSLocale.format(nextblock(totalstaked, TKSD))}
               subData={'$' + nextblock(pricetotalstaked, 18)}
            />
            <Card
               logo={average}
               symbol={''}
               title={t('AverageAPY')}
               data={nextpercent(APR[0][tlock]['APR'], 2) + '%'}
               subData={
                  <p>
                     <span>
                        {t('EARN')} ${TKS[1]}
                     </span>
                  </p>
               }
            />
         </div>

         <div className='grid_area options_wrapper_area'>
            <div className='options_wrapper'>
               <div className='top_area'>
                  <button
                     className={`${active === 1 && 'active'}`}
                     onClick={(e) => {
                        setActive(1);
                        setestimateprice(0);
                        setestimatepriceusd(0);
                        setamountprice(0);
                        setamountpriceusd(0);
                     }}
                  >
                     Stake
                  </button>
                  <button
                     className={`${active === 2 && 'active'}`}
                     onClick={(e) => {
                        setActive(2);
                        setestimateprice(0);
                        setestimatepriceusd(0);
                        setamountprice(0);
                        setamountpriceusd(0);
                     }}
                  >
                     {t('Unstake')}
                  </button>
                  <button
                     className={`${active === 3 && 'active'}`}
                     onClick={(e) => {
                        setActive(3);
                        setestimateprice(0);
                        setestimatepriceusd(0);
                        setamountprice(0);
                        setamountpriceusd(0);
                     }}
                  >
                     {t('Rewards')}
                  </button>
               </div>

               <div className='step'>
                  {active === 1 && (
                     <>
                        <div className='input_wrapper'>
                           <img src={logo} alt=''></img>
                           <p>
                              {t('STAKE')} <span>{TKS[0]}</span> & {t('EARN')} <span>{TKS[1]}</span>
                           </p>
                           <div>
                              <input
                                 type='text'
                                 inputMode='numeric'
                                 onChange={(e) => {
                                    estimateamount(e.target.value);
                                    checkAllow(e.target.value)
                                    estimaterewards((e.target.value * nextpercent(APR[0][tlock]['APR'], 2)) / 100);
                                 }}
                                 id={TKS[0] + TKS[1]}
                                 min={0}
                                 placeholder={t('EnterTheAmountToStake')}
                              />
                              <span>$ {nextblock(amountpriceusd, 18)} USD </span>
                              <span
                                 style={{ cursor: 'pointer' }}
                                 onClick={() => {
                                    document.getElementById(TKS[0] + TKS[1]).value = nextblock(accountBalancemTB, TKSD);
                                    estimateamount(nextblock(accountBalancemTB, TKSD));
                                    checkAllow(nextblock(accountBalancemTB, TKSD))
                                    estimaterewards(
                                       (nextblock(accountBalancemTB, TKSD) * nextpercent(APR[0][tlock]['APR'], 2)) / 100
                                    );
                                 }}
                              >
                                 MAX
                              </span>
                           </div>
                        </div>

                        <div className='tags_wrapper'>
                           <button
                              className={`${tlock === 1 && 'active'}`}
                              onClick={(e) => {
                                 setTlock(1);
                                 estimaterewards(
                                    (document.getElementById(TKS[0] + TKS[1]).value *
                                       nextpercent(APR[0][1]['APR'], 2)) /
                                    100
                                 );
                              }}
                           >
                              {t('PaperHand')}
                           </button>
                           <button
                              className={`${tlock === 3 && 'active'}`}
                              onClick={(e) => {
                                 setTlock(3);
                                 estimaterewards(
                                    (document.getElementById(TKS[0] + TKS[1]).value *
                                       nextpercent(APR[0][3]['APR'], 2)) /
                                    100
                                 );
                              }}
                           >
                              {t('IceHand')}
                           </button>
                           <button
                              className={`${tlock === 6 && 'active'}`}
                              onClick={(e) => {
                                 setTlock(6);
                                 estimaterewards(
                                    (document.getElementById(TKS[0] + TKS[1]).value *
                                       nextpercent(APR[0][6]['APR'], 2)) /
                                    100
                                 );
                              }}
                           >
                              {t('IronHand')}
                           </button>
                           <button
                              className={`${tlock === 12 && 'active'}`}
                              onClick={(e) => {
                                 setTlock(12);
                                 estimaterewards(
                                    (document.getElementById(TKS[0] + TKS[1]).value *
                                       nextpercent(APR[0][12]['APR'], 2)) /
                                    100
                                 );
                              }}
                           >
                              {t('DiamondHand')}
                           </button>
                        </div>

                        <div className='pro'>
                           <div className='step_card'>
                              <div className='presentation pres'>
                                 <img src={phone_card} alt='' />
                                 <div>
                                    <h1>
                                       {t('Timeframe')} ({tlock} {tlock === 1 && t('Month')}
                                       {tlock !== 1 && t('Months')}) :{' '}
                                       <span>{nextpercent(APR[0][tlock]['APR'], 2)} % APY</span>
                                    </h1>
                                    <p>
                                       {t('YourEstimated')} {TKS[1]}'s {t('Rewards2')}:
                                       <span>
                                          {' '}
                                          {nextblock(estimatereward, TKRD)} {TKS[1]}’s ≈ ${' '}
                                          {nextblock(estimaterewardusd, 18)} USD{' '}
                                       </span>{' '}
                                       {t('BasedOnTheCurrent')} {TKS[1]} {t('PriceOf')}{' '}
                                       <span>$ {nextblock(pricecurrentbnb, 18)} USD. </span>
                                    </p>
                                 </div>
                              </div>
                           </div>
                           <div className='input_wrapper stakebutton'>
                              <Stakebutton />
                           </div>
                        </div>
                        <p className='descr'>{t('NBStakingRatesAreVariable')}</p>
                     </>
                  )}
                  {active === 2 && (
                     <>
                        <CardStake logo={logos} symbol={TKS} mouth={1} amount={getamounts(1)} hand={t('PaperHand')} />
                        <CardStake logo={logos} symbol={TKS} mouth={3} amount={getamounts(3)} hand={t('IceHand')} />
                        <CardStake logo={logos} symbol={TKS} mouth={6} amount={getamounts(6)} hand={t('IronHand')} />
                        <CardStake
                           logo={logos}
                           symbol={TKS}
                           mouth={12}
                           amount={getamounts(12)}
                           hand={t('DiamondHand')}
                        />
                     </>
                  )}

                  {active === 3 && (
                     <>
                        <CardReward logo={logor} symbol={TKS} mouth={1} amount={getrewards(1)} hand={t('PaperHand')} />
                        <CardReward logo={logor} symbol={TKS} mouth={3} amount={getrewards(3)} hand={t('IceHand')} />
                        <CardReward logo={logor} symbol={TKS} mouth={6} amount={getrewards(6)} hand={t('IronHand')} />
                        <CardReward
                           logo={logor}
                           symbol={TKS}
                           mouth={12}
                           amount={getrewards(12)}
                           hand={t('DiamondHand')}
                        />
                     </>
                  )}
               </div>
            </div>

            <div className='wallet'>
               <div className='top_area'>
                  <h1>{t('MyWallet')}</h1>
                  <img
                     src={refresh}
                     alt=''
                     id='imgRefresh'
                     onClick={() => {
                        document.getElementById('imgRefresh').style.animation = 'loading 2s linear infinite';
                        props.callupdate();

                        setTimeout(() => {
                           document.getElementById('imgRefresh').style.animation = 'none';
                        }, 1000);
                     }}
                  />
               </div>
               <div className='list_wrapper'>
                  <div className='box'>
                     <img src={wallet} alt='' />
                     <div className='presentation'>
                        <h1>
                           ${TKS[0]} {t('Balance')}
                        </h1>
                        <p>
                           <span>{nextblock(accountBalancemTB, TKSD)}</span>
                           <br /> ${nextblock(pricetotalbalance, 18)} USD
                        </p>
                     </div>
                  </div>
                  <div className='box'>
                     <img src={wallet} alt='' />
                     <div className='presentation'>
                        <h1>
                           {t('Total')} ${TKS[0]} {t('Staked')}
                        </h1>
                        <p>
                           <span>{nextblock(accountNRSA, TKSD)}</span>
                           <br /> ${nextblock(pricetotalstakeduser, 18)} USD
                        </p>
                     </div>
                  </div>
                  <div className='box'>
                     <img src={wallet} alt='' />
                     <div className='presentation'>
                        <h1>
                           {t('Total')} ${TKS[1]} {t('ToClaim')}
                        </h1>
                        {balancereward}
                     </div>
                  </div>
               </div>
            </div>
         </div>

         <div className='grid_area'>
            <div className='chart_area'>
               <h1>
                  {TKS[0]}’s {t('ValueChart')}
               </h1>

               <div className='chart'>
                  <iframe
                     width='100%'
                     height='100%'
                     frameBorder='0'
                     scrolling='no'
                     src={
                        'https://coinbrain.com/embed/bnb-' +
                        tokenct +
                        '?theme=dark&padding=16&chart=1&trades=0&simple=0'
                     }
                  ></iframe>
               </div>
            </div>
            <div className='history_wrapper'>
               <div className='top_area'>
                  <h1> {t('StakeHistory')}</h1>
                  <div className='button_wrapper'>
                     <button
                        className={isactive(1)}
                        onClick={() => {
                           setTimeline(1);
                           ready = false;
                        }}
                     >
                        {t('Today')}
                     </button>
                     <button
                        className={isactive(7)}
                        onClick={() => {
                           setTimeline(7);
                           ready = false;
                        }}
                     >
                        {t('SevenDays')}
                     </button>
                     <button
                        className={isactive(30)}
                        onClick={() => {
                           setTimeline(30);
                           ready = false;
                        }}
                     >
                        {t('ThirtyDays')}
                     </button>
                     <button
                        className={isactive(365)}
                        onClick={() => {
                           setTimeline(365);
                           ready = false;
                        }}
                     >
                        {t('AllTime')}
                     </button>
                  </div>
               </div>
               {ze()}
            </div>
         </div>

      </div>
   );
};
export default Stakemodal;

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const SidebarLink = styled(Link)`
   display: flex;
   color: var(--text-color);
   justify-content: space-between;
   align-items: center;
   padding: 20px;
   list-style: none;
   height: 60px;
   text-decoration: none;
   font-size: 16px;
   font-weight: 500;

   &:hover {
      background: var(--hover);
      cursor: pointer;
   }

   &.actived {
      background: var(--actived);
      color: var(--white);
   }
`;

const SidebarLabel = styled.span``;

const SidebarIcon = styled.span`
   font-size: 25px;

   svg path {
      color: var(--text-color);
   }
`;

const GroupTitle = styled.div`
   display: flex;
   align-items: flex-start;
   flex-direction: column;

   h6 {
      font-weight: 500;
      color: var(--text-cs);
   }
`;

const SidebarGroup = styled.div`
   display: flex;
   align-items: center;
   gap: 16px;
`;

const DropdownLink = styled(Link)`
   background: var(--background-sub-item);
   height: 60px;
   padding-left: 3rem;
   display: flex;
   align-items: center;
   text-decoration: none;
   color: var(--text-color);
   font-size: 13px;

   &.actived-sub {
      background: var(--actived-sub);
      color: var(--white);
   }

   &:hover {
      background: var(--hover);
      cursor: pointer;
   }
`;

const SubMenu = ({ item }) => {
   const [subnav, setSubnav] = useState(false);
   const [itemNav, setItemNav] = useState(1);
   const [subItemNav, setSubItemNav] = useState(11);

   const { t } = useTranslation();

   function setActived(item, i) {
      setSubItemNav(item.id);
   }

   function setItemNavbar(n) {
      setItemNav(n);
   }

   const showSubnav = () => setSubnav(!subnav);

   return (
      <>
         <SidebarLink
            to={item.path}
            onClick={item.id === itemNav && item.subNav && showSubnav}
            onClickCapture={() => setItemNavbar(item.id)}
            id={`nav-${item.id}`}
         >
            <SidebarGroup>
               <SidebarIcon>{item.icon}</SidebarIcon>
               <GroupTitle>
                  <SidebarLabel>{t(item.title)}</SidebarLabel>
                  <h6>{item.comingsoon ? t('ComingSoon') : ''}</h6>
               </GroupTitle>
            </SidebarGroup>
            <div className='icon-open'>
               {item.subNav && subnav ? item.iconOpened : item.subNav ? item.iconClosed : null}
            </div>
         </SidebarLink>
         {subnav &&
            item.subNav.map((item, index) => {
               return (
                  <DropdownLink
                     to={item.path}
                     key={index}
                     onClickCapture={() => setActived(item, index)}
                     className={item.id === subItemNav ? 'actived-sub' : ''}
                  >
                     {item.icon}
                     <GroupTitle>
                        <SidebarLabel>{t(item.title)}</SidebarLabel>
                        <h6>{item.comingsoon ? t('ComingSoon') : ''}</h6>
                     </GroupTitle>
                  </DropdownLink>
               );
            })}
      </>
   );
};

export default SubMenu;

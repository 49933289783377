const ModalSwap = (props) => {

    return (
        <div style={{ color: "#fff", height: "100%", width: "100%", position: "fixed", display: "grid", background: "#00000050", top: "0", left: "0", zIndex: "4000000000000" }} >

            <div style={{ height: "min-content", width: window.innerWidth < 480 ?  window.innerWidth : "480px", display: "grid", gap: "10px", margin: "auto", background: "#121217", borderRadius: "10px", padding: "25px" }}>

                <div style={{ display: "grid", gridTemplateColumns: "auto 50px", height: "50px", width: "100%", gap: "5px", margin: "auto", fontSize: "20px", }}>
                    <p style={{ width: "100%", margin: "auto", textAlign: "left", fontSize: "20px", fontWeight: "bold" }}>HOW TO TRADE</p>
                    <svg onClick={() => { props.sethubtx(false) }} xmlns="http://www.w3.org/2000/svg" style={{ cursor: "pointer", margin: "auto" }} width="25" height="25" fill="#fff" className="bi bi-x" viewBox="0 0 16 16">
                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                    </svg>
                </div>
                <p style={{ width: "100%", margin: "auto", textAlign: "left", fontSize: "12px" }}>
                    Step 1 - Connect you wallet clicking on:
                </p>
                <div style={{ display: "grid", height: "50px", width: "100%", margin: "auto", background: "url('./icons/step1.png')", backgroundRepeat: "no-repeat", backgroundSize: "100%" }}>

                </div>
                <p style={{ width: "100%", margin: "auto", textAlign: "left", fontSize: "12px" }}>
                    Step 2 - Select the Binance Smart Chain Network (BSC):
                </p>
                <div style={{ display: "grid", height: "100px", width: "100%", margin: "auto", background: "url('./icons/step2.png')", backgroundRepeat: "no-repeat", backgroundSize: "100%" }}>

                </div>
                <p style={{ width: "100%", margin: "auto", textAlign: "left", fontSize: "12px" }}>
                    Step 3 - Select SuperStake in this box:
                </p>
                <div style={{ display: "grid", height: "100px", width: "100%", margin: "auto", background: "url('./icons/step3.png')", backgroundRepeat: "no-repeat", backgroundSize: "100%" }}>

                </div>
                <p style={{ width: "100%", margin: "auto", textAlign: "left", fontSize: "12px" }}>
                    Step 4 - And change your slippage to 8%:
                </p>
                <div style={{ display: "grid", height: "50px", width: "100%", margin: "auto", background: "url('./icons/step4.png')", backgroundRepeat: "no-repeat", backgroundSize: "100%" }}>

                </div>
            </div>


        </div>

    );
}

export default ModalSwap;
import React, { useEffect, useState } from 'react';
import bnbabi from '../../abis/bnbabi.json';
import cakeabi from '../../abis/pankakeRouter.json';
import stakesabi from '../../abis/stakes.json';
import './styles.scss';

import Stakehub from './modalstake';

const Web3 = require('web3-eth');

let lastprice = undefined;

function SuperStaking(props) {
   let accounts = props.accounts;

   const [loaded, setLoaded] = useState(false);
   const [myalertview, setmaview] = useState(false);
   const [contentmodal, setcttmodal] = useState(<br />);
   const [provider, setWCrovider] = useState(props.provider);
   const [seconds, setSeconds] = useState(0);
   const [cakes, setCakes] = useState(undefined);
   const [path, setPath] = useState(
      window.location.pathname.toString().split('/')[window.location.pathname.toString().split('/').length - 1]
   );
   useEffect(() => {
      document.title = 'Staking | $ZeldaInu';
      getUserProfile();
   }, []);

   function callupdate() {
      getUserProfile();
   }

   async function getUserProfile() {
      const web3 = new Web3(provider);
      let cake = await new web3.Contract(cakeabi, '0x10ed43c718714eb63d5aa57b78b54704e256024e');
      setCakes(cake);
      /*     const actualData = await fetch(
              'https://supercake-apiv2.azurewebsites.net/api/Dashboard/Contract/Historical/Last30Days?offset=' +
              new Date().getTimezoneOffset() * -1,
              {
                 method: 'GET',
              }
           ).then((response) => response.json())
           lastprice = actualData.current[0].price.usdt;*/
   }
   function array() {
      if (cakes != undefined) {
         /* if (path == 'supercake-bnb') {
             return (
                <Stakehub
                   myalert={myalert}
                   callupdate={callupdate}
                   array={
                      [
                         provider,
                         '0x97C86F37E9c3b0bF7F65968E82D7b96f00aFbab5',
                         stakesabi,
                         '0x2f3b3400427C359F9E4559C4F41C6e6e2D254ACa',
                         bnbabi,
                         '/icons/bnbwlogo.png',
                         '/icons/apresentacao-sem-fundo-150x150.png',
                         '/icons/bnblogo.png',
                         ['SUPERCAKE', 'BNB'],
                         accounts,
                         18,
                         18,
                         cakes,
                         lastprice,
                      ]}
                />
             );
          }
          if (path == 'supercake-superstake') {
             return (
                <Stakehub
                   myalert={myalert}
                   callupdate={callupdate}
                   array={
                      [
                         provider,
                         '0x044990CE4B39Bd136E733043b458c417877564AD',
                         stakesabi,
                         '0x2f3b3400427C359F9E4559C4F41C6e6e2D254ACa',
                         bnbabi,
                         '/icons/bnbwlogo.png',
                         '/icons/apresentacao-sem-fundo-150x150.png',
                         '/icons/0.png',
                         ['SUPERCAKE', 'SUPERSTAKE'],
                         accounts,
                         18,
                         18,
                         cakes,
                         lastprice,
                      ]}
                />
             );
          }*/
                 /* if (path === 'superstake-superstake') {
            return (
               <Stakehub
                  myalert={myalert}
                  callupdate={callupdate}
                  array={[
                     provider,
                     '0xe9B35625516DC7BB9aD4ec3F655C8Ceb61ffC7E8',
                     stakesabi,
                     '0xad5452bE97e257f7312AC7C681407a8B49Ff1408',
                     bnbabi,
                     '/icons/0.png',
                     '/icons/0.png',
                     '/icons/0.png',
                     ['SUPERSTAKE', 'SUPERSTAKE'],
                     accounts,
                     18,
                     18,
                     cakes,
                     lastprice,
                  ]}
               />
            );
         }

         if (path == 'superstake-bnb') {
            return (
               <Stakehub
                  myalert={myalert}
                  callupdate={callupdate}
                  array={
                     [
                        provider,
                        '0x97C86F37E9c3b0bF7F65968E82D7b96f00aFbab5',
                        stakesabi,
                        '0x2f3b3400427C359F9E4559C4F41C6e6e2D254ACa',
                        bnbabi,
                        '/icons/bnbwlogo.png',
                        '/icons/apresentacao-sem-fundo-150x150.png',
                        '/icons/bnblogo.png',
                        ['SUPERCAKE', 'BNB'],
                        accounts,
                        18,
                        18,
                        cakes,
                        lastprice,
                     ]}
               />
            );
         }*/
         if (path == 'staking') {
            return (
               <Stakehub
                  myalert={myalert}
                  callupdate={callupdate}
                  array={[
                     provider,
                     '0x5924c9D131ec6d6C45AED4b9e1D937646e623cF2',
                     stakesabi,
                     '0xadB6CB9550652739c08Cf890Fb135500a9a756a3',
                     bnbabi,
                     '/icons/zeldainu.png',
                     '/icons/zeldainu.png',
                     '/icons/zeldainu.png',
                     ['ZELDAINU', 'ZELDAINU'],
                     accounts,
                     9,
                     9,
                     cakes,
                     lastprice,
                  ]}
               />
            );
         }
      }
   }
   function myalert(e) {
      setmaview(true);
      setcttmodal(
         <div
            style={{
               height: '100%',
               width: '100%',
               maxWidth: '1920px',
               backgroundColor: '#00000000',
               position: 'absolute',
               display: 'grid',
               top: '0',
               left: '0',
               zIndex: '9999999999',
            }}
         >
            <div
               style={{
                  display: 'grid',
                  gridTemplateRows: '96% 4%',
                  padding: '1vh',
                  height: 'min-content',
                  width: '100%',
                  maxWidth: '375px',
                  backgroundColor: '#282828',
                  border: '1px solid #ffc63c',
                  boxShadow: '2px 2px 2px 1px #ffc63c40',
                  borderRadius: '10px',
                  marginBottom: 'auto',
                  marginTop: '10px',
                  marginLeft: 'auto',
                  marginRight: '2px',
               }}
            >
               <div style={{ textAlign: 'left', color: '#ffc63c' }}>
                  Message:
                  <br />
                  <span style={{ textAlign: 'left', color: '#fff' }}>{e}</span>
               </div>
            </div>
         </div>
      );
      setTimeout(function () {
         setmaview(false);
      }, 5000);
   }

   return (
      <div>
         {array()}
         {myalertview && contentmodal}
      </div>
   );
}

export default SuperStaking;

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import './styles.scss';

const style = {
   position: 'absolute',
   top: '50%',
   left: '50%',
   transform: 'translate(-50%, -50%)',
   width: 400,
   bgcolor: 'background.paper',
   border: 'none',
   boxShadow: 0,
   p: 4,
};

export default function FlagsModal() {
   const [open, setOpen] = React.useState(false);
   const handleOpen = () => setOpen(true);
   const handleClose = () => setOpen(false);

   const { i18n, t } = useTranslation();
   const [once, setonce] = React.useState(false);
   const [flag, setflag] = React.useState('/icons/en.png');

   const changeLanguage = (lng, flag) => {
      i18n.changeLanguage(lng);
      setflag(flag);
      handleClose();
   };

   function onl() {
      if (localStorage.getItem('i18nextLng') !== null) {
         if (once) {
         } else {
            let lang = localStorage.getItem('i18nextLng');

            if (lang === 'pt-BR') {
               lang = 'ptBr';
            }

            changeLanguage(lang, '/icons/' + lang + '.png');
            setonce(true);
         }
      }
   }
   onl();

   return (
      <div className='button-flag-group'>
         <Button onClick={handleOpen} className='btn-flag-menu' title={t('Language')}>
            <img src={flag} alt='' />
         </Button>
         <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'
         >
            <Box sx={style} className='modal-flags'>
               <div className='group-flags'>
                  <button type='button' className='btn-flag' onClick={() => changeLanguage('en', '/icons/en.png')}>
                     <img src='/icons/en.png' alt='' />
                  </button>
                  <button type='button' className='btn-flag' onClick={() => changeLanguage('ptBr', '/icons/ptBr.png')}>
                     <img src='/icons/ptBr.png' alt='' />
                  </button>
                  <button type='button' className='btn-flag' onClick={() => changeLanguage('chi', '/icons/chi.png')}>
                     <img src='/icons/chi.png' alt='' />
                  </button>
                  <button type='button' className='btn-flag' onClick={() => changeLanguage('jpn', '/icons/jpn.png')}>
                     <img src='/icons/jpn.png' alt='' />
                  </button>
                  <button type='button' className='btn-flag' onClick={() => changeLanguage('iran', '/icons/iran.png')}>
                     <img src='/icons/iran.png' alt='' />
                  </button>
               </div>
            </Box>
         </Modal>
      </div>
   );
}

import './style.css';
import ModalSwap from "./modalswap";

import React, { useEffect, useState } from 'react';

function SuperSwap(props) {
    let accounts = props.accounts
    const [hubtx, sethubtx] = useState(true)
    useEffect(() => {
        document.title = 'SuperApp | $SuperPancake';

    }, []);


    return (
        <div style={{height: window.innerHeight + 20, width: "100%", display: "grid", background: "#121217" }}>
            <iframe scrolling='no' src={"/swap.html"} style={{
                borderRadius: "10px", border: "none", borderLeft: "1px solid #ffffff20", borderRight: "1px solid #ffffff20", width: "50vh", height: window.innerHeight + 50, margin: "auto", marginTop: "20px",
            }} />
            {hubtx && <ModalSwap sethubtx={sethubtx}/>}
        </div>
    );
}

export default SuperSwap;

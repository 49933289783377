import ChartThirtyDays from './chartThirtyDays/chartThirtyDays';

import './chart.scss';

const ChartsBurntSuperstake = (props) => {
   return (
      <>
         <div className='chart-margin'>
            <div className='chart-col-12'>
               <ChartThirtyDays burntTokenAmount={props.burntTokenAmount} />
            </div>
         </div>
      </>
   );
};

export default ChartsBurntSuperstake;

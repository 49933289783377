import Sidebar from './components-nav/Sidebar';
import './styles.scss';

const Nav = () => {
   return (
      <>
         <div className='nav'>
            <Sidebar />
         </div>

         <div className='ads-nav'>
           {false && <img src='/ads/ads-quadrado.gif' alt='ads' />}
         </div>
      </>
   );
};

export default Nav;

import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import chi from './lng/china.json';
import en from './lng/en.json';
import iran from './lng/iran.json';
import jpn from './lng/jpn.json';
import ptBr from './lng/pt-br.json';

i18n
   .use(LanguageDetector)
   .use(initReactI18next)
   .init({
      resources: {
         en: {
            translations: en,
         },
         ptBr: {
            translations: ptBr,
         },
         chi: {
            translations: chi,
         },
         jpn: {
            translations: jpn,
         },
         iran: {
            translations: iran,
         },
      },
      fallbackLng: 'en',
      debug: false,

      ns: ['translations'],
      defaultNS: 'translations',

      keySeparator: false,

      interpolation: {
         escapeValue: false,
      },
   });

export default i18n;

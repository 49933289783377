import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import WalletConnectProvider from '@walletconnect/web3-provider';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Routes } from 'react-router-dom';
import Nav from './components/nav/nav';
import Social from './components/social/social';
import Top from './components/top/top';
import ComingSoom from './pages/coming-soom';
import DashboardSC from './pages/dashboard/dashboard-sc';
import DashboardSS from './pages/dashboard/dashboard-ss';
import SuperSwap from './pages/superSwap/superSwap';
import SuperStaking from './pages/superstaking/index';
import Dashboard from './pages/dashboard/dashboard';

const Web3 = require('web3-eth');
const wprovider = new WalletConnectProvider({
   rpc: {
      56: 'https://bsc-dataseed.binance.org/',
   },
   bridge: 'https://bridge.walletconnect.org',
});
const Main = () => {
   const { t } = useTranslation();
   const [open, setOpen] = React.useState(false);
   const handleOpen = () => setOpen(true);
   const handleClose = () => setOpen(false);
   const [selectwallet, setselectwallet] = useState(false);
   const [accounts, setAccounts] = useState(0);
   const [loaded, setLoaded] = useState(false);
   const [provider, setWCrovider] = useState(wprovider);
   const [conerror, setconerror] = useState(true);
   const [errornet, seterrornet] = useState(false);
   const [texterror, seterrormenssage] = useState(<h1 className='errormsg'></h1>);

   useEffect(() => {
      let wpp = localStorage.getItem('wprovider');
      if (wpp != null) {
         let wp = wpp.split(',');
         if (wp != null) {
            if (wp[0] === 'wc') {
               if (wp[1] !== 0) {
                  connecttowalletconnect();
               }
            } else {
               if (wp[1] !== 0) {
                  connecttometamask();
               }
            }
         }
      } else {
      }
   }, []);
   useEffect(() => {
      if (loaded && accounts !== 0) {
         let options = {
            filter: {
               address: [accounts[0]],
            },
         };
      } else {
         setTimeout(setLoaded(true), 500);
      }

      let wpp = localStorage.getItem('wprovider');
      if (wpp != null) {
         let wp = wpp.split(',');
         if (wp[0] === 'wc') {
            provider.on('disconnect', (code, reason) => {
               localStorage.removeItem('wprovider');
               window.location.reload();
            });
         } else {
            if (window.ethereum != null || window.ethereum != undefined)
               window.ethereum.on('accountsChanged', (accounts) => {
                  if (accounts.length > 0) {
                  } else {
                     Disconnect();
                  }
               });
         }
      }
   }, [loaded, accounts]);

   function connectMetaMask() {
      window.web3
         .requestAccounts()
         .then((result) => {
            setAccounts(result);
            setconerror(false);
            const a = result;
            if (loaded && accounts !== 0) {
               let accounts = localStorage.getItem('wprovider');
               if (accounts !== null) {
                  let account = accounts.split(',');
                  if (account[1] === a[0]) {
                  } else {
                     window.location.reload();
                  }
               }
            }
            localStorage.setItem('wprovider', ['mt', a[0]]);
         })
         .catch((error) => {
            setconerror(true);
         });
   }

   function Disconnect() {
      if (localStorage.getItem('wprovider') != null) {
         let wpp = localStorage.getItem('wprovider');
         if (wpp != null) {
            let wp = wpp.split(',');
            if (wp[0] === 'wc') {
               provider.disconnect();
            } else {
            }
         }
         localStorage.removeItem('wprovider');
         window.location.reload();
      } else {
      }
   }

   function connecttometamask() {
      window.web3 = new Web3(window.ethereum);
      connectMetaMask();
   }
   async function connecttowalletconnect() {
      if (typeof web3 !== 'undefined') {
         //infuraId: "27e484dcd9e3efcfd25a83a78777cdf1",
         provider.on('chainChanged', (chainId) => {
            if (chainId !== 56) {
               window.location.reload();
            } else {
               setLoaded(true);
            }
         });
         await provider
            .enable()
            .then(() => {
               const web3 = new Web3(provider);
               window.web3 = web3;
               setAccounts([provider.accounts[0]]);
               const a = provider.accounts[0];
               if (loaded && accounts !== 0) {
                  let accounts = localStorage.getItem('wprovider');
                  if (accounts != null) {
                     let account = accounts.split(',');
                     if (account[1] === a) {
                     } else {
                        window.location.reload();
                     }
                  }
               }

               localStorage.setItem('wprovider', ['wc', a]);
            })
            .catch((error) => {
               setWCrovider(
                  new WalletConnectProvider({
                     rpc: {
                        56: 'https://bsc-dataseed.binance.org/',
                     },
                     bridge: 'https://bridge.walletconnect.org',
                  })
               );
            });
      } else {
         provider.on('chainChanged', (chainId) => {
            if (chainId !== 56) {
               window.location.reload();
            } else {
               setLoaded(true);
            }
         });
         await provider
            .enable()
            .then(() => {
               const web3 = new Web3(provider);
               window.web3 = web3;
               setAccounts([provider.accounts[0]]);
               const a = provider.accounts[0];
               if (loaded && accounts !== 0) {
                  let accounts = localStorage.getItem('wprovider');
                  if (accounts != null) {
                     let account = accounts.split(',');
                     if (account[1] === a) {
                     } else {
                        window.location.reload();
                     }
                  }
               }

               localStorage.setItem('wprovider', ['wc', a]);
            })
            .catch((err) => {
               console.log('Error when connecting via walletConnect');
               console.log(err);
               setWCrovider(
                  new WalletConnectProvider({
                     rpc: {
                        56: 'https://bsc-dataseed.binance.org/',
                     },
                     bridge: 'https://bridge.walletconnect.org',
                  })
               );
            });
      }
   }
   const buttoconect = () => {
      return accounts !== 0 ? accounts[0].slice(0, 4) + '...' + accounts[0].slice(-4) : `${t('Connect')}`;
   };
   function acc() {
      if (accounts !== 0) {
         return accounts[0];
      } else {
         return undefined;
      }
   }

   function prove() {
      let prov;
      let wpp = localStorage.getItem('wprovider');
      if (wpp != null) {
         let wp = wpp.split(',');
         if (wp[0] === 'wc') {
            prov = provider;
         } else {
            prov = window.ethereum;
         }
      } else {
         prov = 'https://bsc-dataseed.binance.org';
      }
      return prov;
   }

   const Bwallet = (props) => {
      return (
         <div className='connect' title={props.text} onClick={props.click}>
            <div className='group-753 flex-row-vend'>
               <div className='group-576'>
                  <div className='group-3100'>
                     <svg
                        className='wicon'
                        xmlns='http://www.w3.org/2000/svg'
                        width='16'
                        height='16'
                        fill='#262626'
                        viewBox='0 0 16 16'
                     >
                        <path d='M12.136.326A1.5 1.5 0 0 1 14 1.78V3h.5A1.5 1.5 0 0 1 16 4.5v9a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 13.5v-9a1.5 1.5 0 0 1 1.432-1.499L12.136.326zM5.562 3H13V1.78a.5.5 0 0 0-.621-.484L5.562 3zM1.5 4a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5h-13z' />
                     </svg>
                  </div>
               </div>
               <p className='txt-647'>{props.text}</p>
            </div>
         </div>
      );
   };
   const buttoncolor = () => {
      const buttonwallet = errornet ? (
         <Bwallet click={() => { }} text={texterror} />
      ) : (
         <Bwallet
            click={() => {
               setselectwallet(true);
               handleOpen();
            }}
            text={buttoconect()}
         />
      );
      return buttonwallet;
   };
   const style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '200px',
      bgcolor: 'background.paper',
      border: 'none',
      boxShadow: 0,
      p: 4,
   };
   let hvga = (
      <div className='button-flag-group'>
         <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'
         >
            <Box sx={style} className='modal-wallets'>
               <div className='group-wallets'>
                  <button
                     type='button'
                     className='btn-flag btn-wallet'
                     onClick={() => {
                        connecttometamask();
                        handleClose();
                     }}
                  >
                     <img src='https://cdn.iconscout.com/icon/free/png-256/metamask-2728406-2261817.png' alt='' />
                  </button>
                  <button
                     type='button'
                     className='btn-flag btn-wallet'
                     onClick={() => {
                        connecttowalletconnect();
                        handleClose();
                     }}
                  >
                     <img src='/icons/wc.ico' alt='' />
                  </button>
               </div>
            </Box>
         </Modal>
      </div>
   );
   function checkpathtitle(e) {
      let a = window.location.pathname.toString().split('/');

      if (window.location.pathname === '/') {
         return '';
      } else {
         if (
            e.toString().toUpperCase() === window.location.pathname.toString().split('/')[a.length - 1].toUpperCase()
         ) {
            return '';
         } else {
            return '/' + window.location.pathname.toString().split('/')[a.length - 1].toUpperCase();
         }
      }
   }
   /*
      <Routes>
               <Route path='/' exact element={<DashboardSC accounts={acc()} provider={prove()} />}></Route>
               <Route path='/superdashboard' element={<DashboardSC accounts={acc()} provider={prove()} />}></Route>
               <Route path='/dashboard' element={<DashboardSC accounts={acc()} provider={prove()} />}></Route>
               <Route
                  path='/dashboard/superpancake'
                  element={<DashboardSC accounts={acc()} provider={prove()} />}
               ></Route>
               <Route
                  path='/dashboard/superstake'
                  element={<DashboardSS accounts={acc()} provider={prove()} />}
               ></Route>
               <Route
                  path='/superstaking/superstake-superstake'
                  element={<SuperStaking accounts={acc()} provider={prove()} />}
               ></Route>
               <Route
                  path={'/superstaking' + checkpathtitle('superstaking')}
                  element={<SuperStaking accounts={acc()} provider={prove()} />}
               ></Route>
               <Route path='/superswap' element={<SuperSwap accounts={acc()} provider={prove()} />}></Route>
               <Route path='/comingsoon' element={<ComingSoom accounts={acc()} provider={prove()} />}></Route>
            </Routes>
   */
   return (
      <div id='content-wrapper'>
         <header style={{ width: '100%' }}>
            <Top props={buttoncolor()} />
         </header>
         <nav id='sidemenu'>
            <Nav />
         </nav>
         <main id='main'>
            <Routes>
               <Route path='/' exact element={<Dashboard accounts={acc()} provider={prove()} />}></Route>

               <Route
                  path='/staking'
                  element={<SuperStaking accounts={acc()} provider={prove()} />}
               ></Route>

               <Route path='/swap' element={<SuperSwap accounts={acc()} provider={prove()} />}></Route>
               <Route path='/comingsoon' element={<ComingSoom accounts={acc()} provider={prove()} />}></Route>
            </Routes>
         </main>
         <footer id='footer'>
            <Social />
         </footer>
         {selectwallet && hvga}
      </div>
   );
};

export default Main;

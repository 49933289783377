import React, { useState, useEffect } from 'react';
import { IconContext } from 'react-icons/lib';
import styled from 'styled-components';
import { SidebarData } from './SidebarData';
import SubMenu from './SubMenu';

const SidebarNav = styled.nav`
   background: #15171c;
   width: 250px;
   height: ${({ sidebar }) => (!sidebar ? 'calc(100vh - 351px)' : 'calc(100vh - 251px)')};
   display: flex;
   justify-content: center;
   position: fixed;
   z-index: 9;
`;

const SidebarWrap = styled.div`
   width: 100%;
`;

const Sidebar = () => {
   const [sidebar, setSidebar] = useState(true);

   useEffect(() => {
      checkDevice();
   }, []);

   function checkDevice() {
      if (
         navigator.userAgent.match(/Android/i) ||
         navigator.userAgent.match(/webOS/i) ||
         navigator.userAgent.match(/iPhone/i) ||
         navigator.userAgent.match(/iPad/i) ||
         navigator.userAgent.match(/iPod/i) ||
         navigator.userAgent.match(/BlackBerry/i) ||
         navigator.userAgent.match(/Windows Phone/i)
      ) {
         setSidebar(true); // is mobile
      } else {
         setSidebar(false); // is not mobile
      }
   }

   return (
      <>
         <IconContext.Provider value={{ color: '#fff' }}>
            <SidebarNav sidebar={sidebar}>
               <SidebarWrap>
                  {SidebarData.map((item, index) => {
                     return <SubMenu item={item} key={index} />;
                  })}
               </SidebarWrap>
            </SidebarNav>
         </IconContext.Provider>
      </>
   );
};

export default Sidebar;

import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import 'balloon-css';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import miniabi from '../../abis/coin.json';
import cakezona from '../../abis/minimetaabi.json';
import cakeabi from '../../abis/pankakeRouter.json';
import ChartsBurntSupercake from '../../components/burnt-supercake/chart';
import Charts from '../../components/charts/chart';
import ChartsHolderBalance from '../../components/holder-balance/chart';
import ChartsHolderCount from '../../components/holder-count/chart';
import StakeHub from '../../components/stakehub/stakehub';
import {
   LoadHolderWalletDashboard,
   LoadIndicatorsDashboard,
   LoadLastSevenDaysDashboard,
   LoadLastThirtyDaysDashboard,
   LoadLastThirtyDaysDashboardBurntToken,
   LoadPerHoursDashboard,
} from '../../services/dashboard';

import Empty from '../../components/empty/empty';
import Loading from '../../components/loading/loading';
import '../css/main.scss';
const Web3 = require('web3-eth');

const Dashboard = (props) => {
   const domain = 'superpancake';
   let accounts = props.accounts;

   const { t } = useTranslation();
   const [conerror, setconerror] = useState(true);
   const [tokenBCT, setTokenBCT] = useState('0x2f3b3400427C359F9E4559C4F41C6e6e2D254ACa');
   const [accountBalancemTB, setAccountBalanceTB] = useState(0);
   const [accountBalancemc, setAccountBalancemc] = useState(0);
   const [accountBalancemctTB, setAccountBalancectTB] = useState(0);
   const [accountBalancemTBR, setAccountReward] = useState(0);
   const [accountBalancemTBCake, setAccountBalancectTBcake] = useState(0);
   const [datatoken, setDTtoken] = useState();
   const [swapThreshold, setSwapThreshold] = useState(0);
   const [tprice, settokenprice] = useState('0');
   const [tpricecake, setcakeprice] = useState('0');
   const [tpricecakeu, setcakepriceu] = useState('0');
   const [runanim, setRunAnim] = useState(false);
   const [progressbarview, setpbview] = useState(false);
   const [myalertview, setmaview] = useState(false);
   const [priceset, setpset] = useState(false);
   const [pricesetc, setpsetc] = useState(false);
   const [contentmodal, setcttmodal] = useState(<br />);
   const [provider, setWCrovider] = useState(props.provider);
   const [loading, setLoading] = useState(true);
   const [perHour, setPerHour] = useState([]);
   const [lastSevenDays, setLastSevenDays] = useState([]);
   const [lastThirtyDays, setLastThirtyDays] = useState([]);
   const [contractHistorical, setContractHistorical] = useState([]);

   useEffect(() => {
      document.title = 'SuperApp | $SuperPancake';
   }, []);

   useEffect(() => {
      (async () => {
         if (!!accounts) {
            await getUserProfile();

            const [
               wallet,
               perHourData,
               lastSevenDaysData,
               lastThirtyDaysData,
               lastThirtyDaysDataBurntToken,
               indicators,
            ] = await Promise.all([
               LoadHolderWalletDashboard(accounts, domain),
               LoadPerHoursDashboard(accounts, domain),
               LoadLastSevenDaysDashboard(accounts, domain),
               LoadLastThirtyDaysDashboard(accounts, domain),
               LoadLastThirtyDaysDashboardBurntToken(domain),
               LoadIndicatorsDashboard(domain),
            ]);

            setPerHour(perHourData);
            setLastSevenDays(lastSevenDaysData);
            setLastThirtyDays(lastThirtyDaysData);
            setContractHistorical(lastThirtyDaysDataBurntToken);
            setDTtoken([
               indicators.totalSupply,
               indicators.burntSupply,
               indicators.initialSupply,
               indicators.holdersCount,
               indicators.burntSupplyPercentage,
            ]);

            setLoading(false);
         }
      })();
   }, [accounts]);

   async function getUserProfile() {
      const web3 = new Web3(provider);

      const [cake, cakect, tb] = await Promise.all([
         new web3.Contract(cakeabi, '0x10ed43c718714eb63d5aa57b78b54704e256024e'),
         new web3.Contract(cakezona, '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82'),
         new web3.Contract(miniabi, tokenBCT),
      ]);

      if (!!accounts) {
         call(cakect.methods.balanceOf, setAccountBalancemc, accounts);
         call(cakect.methods.balanceOf, setAccountBalancectTBcake, tokenBCT);
         call(tb.methods.balanceOf, setAccountBalancectTB, tokenBCT);
         call(tb.methods.getUnpaidEarnings, setAccountReward, accounts);
         call(tb.methods.balanceOf, setAccountBalanceTB, accounts);
         call(tb.methods.swapThreshold, setSwapThreshold);

         getUSDprice(cake, accountBalancemTB, settokenprice, '0x2f3b3400427C359F9E4559C4F41C6e6e2D254ACa');
         getUSDprice(cake, accountBalancemTBR, setcakeprice, '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82');
         getUSDprice(cake, accountBalancemTBCake, setcakepriceu, '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82');
      }
   }

   function call(func, callback, ...args) {
      func(...args)
         .call()
         .then((result) => {
            callback(result);
            setconerror(false);
         })
         .catch((error) => {});
   }

   async function getUSDprice(cake, amountin, callback, tokenA) {
      if (!!accounts) {
         if (amountin > 0) {
            const p1 = await cake.methods
               .getAmountsOut(amountin, [tokenA, '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c'])
               .call();
            const p2 = await cake.methods
               .getAmountsOut(p1[1], [
                  '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
                  '0xe9e7cea3dedca5984780bafc599bd69add087d56',
               ])
               .call();

            callback(p2[1]);
            setpset(true);
         }
      }
   }

   //--------------------------------------
   function progressview() {
      if (runanim) {
         if (progressbarview) {
         } else {
            setpbview(true);
         }
      } else {
         if (progressbarview) {
            setpbview(false);
         } else {
         }
      }
   }

   //--------------------------------------
   progressview();
   function nextblock(accountBalancemctTB) {
      if ((accountBalancemctTB / 1000000000000000000).toString().includes('.')) {
         if (accountBalancemctTB.toString().length >= 18) {
            return (
               (accountBalancemctTB / 1000000000000000000).toString().split('.')[0] +
               '.' +
               (accountBalancemctTB / 1000000000000000000).toString().split('.')[1].slice(0, 2)
            );
         } else {
            if ((accountBalancemctTB / 1000000000000000000).toString().includes('e')) {
               if (accountBalancemctTB.toString().length >= 9) {
                  return (
                     '0.' +
                     '0'.repeat(18 - accountBalancemctTB.toString().length) +
                     accountBalancemctTB.toString().slice(0, 2)
                  );
               } else {
                  return '0.00';
               }
            } else {
               if (accountBalancemctTB.toString().length >= 9) {
                  return (
                     '0.' +
                     '0'.repeat(18 - accountBalancemctTB.toString().length) +
                     accountBalancemctTB.toString().slice(0, 2)
                  );
               } else {
                  return '0.00';
               }
            }
         }
      } else {
         return accountBalancemctTB / 1000000000000000000;
      }
   }

   function percentblock(value) {
      if ((value * 100).toString().includes('.')) {
         return (value * 100).toString().split('.')[0] + '.' + (value * 100).toString().split('.')[1].slice(0, 2);
      } else {
         return value * 100;
      }
   }

   let dollarUSLocale = Intl.NumberFormat('en-US');

   //- used for tabs ----------------------------------------------------------------------------------------------
   function TabPanel(props) {
      const { children, value, index, ...other } = props;

      return (
         <div
            role='tabpanel'
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
         >
            {value === index && (
               <Box sx={{ p: 3 }}>
                  <Typography>{children}</Typography>
               </Box>
            )}
         </div>
      );
   }

   TabPanel.propTypes = {
      children: PropTypes.node,
      index: PropTypes.number.isRequired,
      value: PropTypes.number.isRequired,
   };

   function a11yProps(index) {
      return {
         id: `simple-tab-${index}`,
         'aria-controls': `simple-tabpanel-${index}`,
      };
   }

   const [value, setValue] = React.useState(0);

   const handleChange = (event, newValue) => {
      setValue(newValue);
   };

   const HS = (props) => {
      return (
         <div className='holder-card'>
            <div className='holder-card-name'>
               {props.title}

               <button
                  aria-label={props.blockI}
                  data-balloon-pos='up-right'
                  data-balloon-length='medium'
                  className='icedit'
               >
                  <i class='fa-solid fa-circle-info'></i>
               </button>
            </div>

            {!accounts && <div className='value-empty'>-</div>}
            {props.loading && !!accounts && <Loading />}
            {!props.loading && <div className='holder-amount'>{props.amount ? props.amount : ''}</div>}
            {!props.loading && <div className='holder-value'>{props.price ? props.price : ''}</div>}
         </div>
      );
   };

   return (
      <>
         <div className='container'>
            {myalertview && contentmodal}
            <section>
               <div>
                  <div className='group-logo'>
                     <div className='logo-text' style={{ borderRadius: '360px' }}>
                        <img src='/icons/logo.jpeg' style={{ borderRadius: '360px' }} className='logo-Text' alt='' />
                     </div>
                     <div className='logo-text'>
                        <div className='section-title'>{t('HoldersRewards')}</div>
                        <h3>{t('HoldTokensSupercakeToEarnCake')}</h3>

                        <button
                           title={t('BuyAndEarn')}
                           type='button'
                           onClick={() => {
                              window.open(
                                 'https://pancakeswap.finance/swap?outputCurrency=0x2f3b3400427c359f9e4559c4f41c6e6e2d254aca'
                              );
                           }}
                        >
                           {t('BuyAndEarn')}
                        </button>
                     </div>
                  </div>
               </div>
            </section>

            <div className='ads ads-to-mobile'>
               <img src='/ads/ads-quadrado.gif' alt='ads' />
            </div>

            <section className='space-bottom'>
               <div className='token-status'>
                  <div className='group-token-status'>
                     <div className='token-card'>
                        <h2>Supply Inicial</h2>
                        {!accounts && <div className='value-empty'>-</div>}
                        {!datatoken && !!accounts && <Loading />}
                        {datatoken && <div className='token-amount'>{dollarUSLocale.format(datatoken[2])}</div>}
                     </div>

                     <div className='token-card'>
                        <h2>Current Supply</h2>
                        {!accounts && <div className='value-empty'>-</div>}
                        {!datatoken && !!accounts && <Loading />}
                        {datatoken && <div className='token-amount'>{dollarUSLocale.format(datatoken[0])}</div>}
                     </div>

                     <div className='token-card'>
                        <h2>Burnt $Supercake</h2>
                        {!accounts && <div className='value-empty'>-</div>}
                        {!datatoken && !!accounts && <Loading />}
                        {datatoken && <div className='token-amount'> {dollarUSLocale.format(datatoken[1])}</div>}
                        {datatoken && <div className='token-amount-sub'>{dollarUSLocale.format(datatoken[4])}%</div>}
                     </div>

                     <div className='token-card'>
                        <h2>Holders</h2>
                        {!accounts && <div className='value-empty'>-</div>}
                        {!datatoken && !!accounts && <Loading />}
                        {datatoken && <div className='token-amount'>{dollarUSLocale.format(datatoken[3])}</div>}
                     </div>
                  </div>
               </div>
            </section>

            <Box sx={{ width: '100%' }}>
               <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <Tabs value={value} onChange={handleChange} aria-label='basic tabs example'>
                     <Tab label={t('HolderRewardsStatistics')} {...a11yProps(0)} />
                     <Tab label={t('BurntSupercake')} {...a11yProps(1)} />
                     <Tab label={t('HolderBalance')} {...a11yProps(2)} />
                     <Tab label={t('HolderCount')} {...a11yProps(3)} />
                  </Tabs>
               </Box>
               <TabPanel value={value} index={0}>
                  {!!accounts && (
                     <Charts
                        loading={loading}
                        account={accounts}
                        array={['SUPERCAKE', 'CAKE']}
                        accountprop={accounts.toString()}
                        perHour={perHour}
                        lastSevenDays={lastSevenDays}
                        lastThirtyDays={lastThirtyDays}
                     />
                  )}
                  {!accounts && <Empty />}
               </TabPanel>

               <TabPanel value={value} index={1}>
                  {!!accounts && <ChartsBurntSupercake burntTokenAmount={contractHistorical} />}
                  {!accounts && <Empty />}
               </TabPanel>

               <TabPanel value={value} index={2}>
                  {!!accounts && <ChartsHolderBalance holderBalance={lastThirtyDays} />}
                  {!accounts && <Empty />}
               </TabPanel>

               <TabPanel value={value} index={3}>
                  {!!accounts && <ChartsHolderCount holdersCount={contractHistorical} />}
                  {!accounts && <Empty />}
               </TabPanel>
            </Box>

            <hr className='separator' />

            <section>
               <div className='holder-status'>
                  <div className='section-title'> {t('HolderStatus')}</div>
                  <div className='group-holder-status'>
                     <HS
                        title={t('SupercakeBalance')}
                        blockI={t('blockinfo1')}
                        loading={loading}
                        amount={nextblock(accountBalancemTB)}
                        price={'$ ' + nextblock(tprice) + ' USD'}
                     />
                     <HS
                        title={t('CakeBalance')}
                        blockI={t('blockinfo2')}
                        loading={loading}
                        amount={nextblock(accountBalancemTBCake)}
                        price={'$ ' + nextblock(tpricecakeu) + ' USD'}
                     />
                     <HS
                        title={t('CakeToReceive')}
                        blockI={t('blockinfo3')}
                        loading={loading}
                        amount={nextblock(accountBalancemTBR)}
                        price={'$ ' + nextblock(tpricecake) + ' USD'}
                     />
                     <HS
                        title={t('SupercakeToEarnCake')}
                        blockI={t('blockinfo4')}
                        loading={loading}
                        amount={percentblock(nextblock(accountBalancemctTB) / nextblock(swapThreshold)) + '%'}
                        price={nextblock(accountBalancemctTB) + ` ${t('OnBlock')}`}
                     />
                  </div>
               </div>
            </section>

            <hr className='separator' />

            <section className='space-bottom'>
               <div className='holder-status'>
                  <div className='section-title'>{t('SuperStakingComingsoon')}</div>
                  <StakeHub />
               </div>
            </section>

            <div className='ads'>
               <img src='/ads/ads-2.gif' alt='ads' />
            </div>
            <div className='ads ads-to-mobile'>
               <img src='/ads/ads-quadrado.gif' alt='ads' />
            </div>

            <div className='ads '>
               <img src='/ads/ads-1.gif' alt='ads' />
               <img src='/ads/ads-1.gif' alt='ads' />
            </div>
         </div>

         <div className='wallet-fixed'></div>
      </>
   );
};

export default Dashboard;

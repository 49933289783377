import { useEffect, useState } from 'react';

import './App.scss';
import Main from './Main';

function App() {

   return (
      <Main />
   );
}

export default App;

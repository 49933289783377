import { useTranslation } from 'react-i18next';
import { IconContext } from 'react-icons';
import * as RiIcons from 'react-icons/ri';
import styled from 'styled-components';

const Icon = styled.span`
   font-size: 8rem;
   height: 80px;
   display: flex;
   justify-content: flex-start;
   align-items: center;
   color: 'rgb(255, 198, 60)';
`;

const ContainerComingSoom = styled.div`
   width: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
   height: 100%;
   flex-direction: column;

   h2 {
      color: var(--color-text-primary);
      font-weight: 500;
   }
`;

const ComingSoom = () => {
   const { t } = useTranslation();

   return (
      <IconContext.Provider value={{ color: 'rgb(255, 198, 60)' }}>
         <ContainerComingSoom>
            <Icon>
               <RiIcons.RiCodeView />
            </Icon>
            <h2>{t('ComingSoon')}</h2>
         </ContainerComingSoom>
      </IconContext.Provider>
   );
};

export default ComingSoom;
